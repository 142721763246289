import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from '../Url/url.service';

@Injectable({
  providedIn: 'root'
})
export class VariantService {

  constructor(private url:UrlService,private http:HttpClient) { }
  serverUrl=`${this.url.url}/variant`

  async addVariant(formData){
    return this.http.post(`${this.serverUrl}`,formData).toPromise()
  }
  async getAllVariants(){
    return this.http.get(`${this.serverUrl}/`).toPromise()
  }

  

  async toggleVariant(id:any,obj:any){
    return this.http.patch(`${this.serverUrl}/toggleVariant/${id}`,obj).toPromise()
  }
  async removeVariant(id:any,obj:any){
    return this.http.patch(`${this.serverUrl}/removeVariant/${id}`,obj).toPromise()
  }
}
