import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from '../Url/url.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private url:UrlService,private http:HttpClient) { }
  serverUrl=`${this.url.url}/category`


  addCategory(formData){
    return this.http.post(`${this.serverUrl}/`,formData).toPromise()
  }

  getAllCategories(){
    return this.http.get(`${this.serverUrl}/getAllCategories`).toPromise()
  }
  changeCategoryPosition(formData){
    return this.http.patch(`${this.serverUrl}/categoryPositionAdjust`,formData).toPromise()
  }
  

}
