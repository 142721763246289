import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlService } from '../Url/url.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private url:UrlService,private http:HttpClient) { }
  serverUrl=`${this.url.url}/product`

  async addProduct(formData){
    return this.http.post(`${this.serverUrl}`,formData).toPromise()
  }
  async updateById(id,formData){
    return this.http.patch(`${this.serverUrl}/updateById/${id}`,formData).toPromise()
  }
  async getAllProducts(){
    return this.http.get(`${this.serverUrl}/`).toPromise()
  }

  async getProductById(id){
    return this.http.get(`${this.serverUrl}/getById/${id}`).toPromise()
  }
  

  async toggleProduct(id:any,obj:any){
    return this.http.patch(`${this.serverUrl}/toggleProduct/${id}`,obj).toPromise()
  }
  async removeProduct(id:any){
    return this.http.patch(`${this.serverUrl}/removeProduct/${id}`, {}).toPromise()
  }
}
