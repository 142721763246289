import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  // url = "http://eatablesapi.ebslon.com:4009" // when making this live make sure to comment the stuff that we have to comment for eatables
  // url = "http://project21api.ebslon.com:4040" // when making this live make sure to comment the stuff that we have to comment for project21
  // url = "http://192.168.0.122:4009"
  // url="http://192.168.0.63:4040"
  url="/api"
  constructor() { }
  generateFileUrl = (file: any) => {
    return `${this.url}/uploads/${file}`
  }

}
