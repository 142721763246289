import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from '../Url/url.service';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  url = `${this.serverUrl.url}/Brands`
  constructor(private http: HttpClient, private serverUrl: UrlService) { }


  async addNewBrand(formData:any){
    return this.http.post(`${this.url}/newBrand`,formData).toPromise()
  }

  async uploadBrandImage(formData:any){
    return this.http.post(`${this.url}/uploadBrandImage`,formData).toPromise()
  }

  async getAllBrands(){
    return this.http.get(`${this.url}/getAllBrands`).toPromise()
  }

  

  async toggleBrands(id:any,obj:any){
    return this.http.patch(`${this.url}/toggleBrand/${id}`,obj).toPromise()
  }
  async removeBrands(id:any,obj:any){
    return this.http.patch(`${this.url}/removeBrand/${id}`,obj).toPromise()
  }

}
