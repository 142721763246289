import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlService } from '../Url/url.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private Url: UrlService, private http: HttpClient) { }
  url = `${this.Url.url}/order`

  async getAllOrders() {
    return this.http.get(`${this.url}/`).toPromise()
  }

  async dispatchOrder(id, obj = { }) {
    return this.http.patch(`${this.url}/orderDispatched/${id}`, obj).toPromise()
  }

  async deliverOrder(id, obj = { }) {
    return this.http.patch(`${this.url}/orderDelivered/${id}`, obj).toPromise()
  }


  getOrderNameFromStatus(status: number) {
    if (status == 0) {
      return 'Order Placed'
    }
    else if (status == 1) {
      return 'Order Dispatched'
    }
    else if (status == 2) {
      return 'Order Delivered'
    }
    else {
      return ''
    }
  }



}
