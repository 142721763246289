import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { TreeviewModule } from 'ngx-treeview';

// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { AuthService } from './shared/services/firebase/auth.service';
import { AdminGuard } from './shared/guard/admin.guard';
import { SecureInnerPagesGuard } from './shared/guard/SecureInnerPagesGuard.guard';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";

import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';

import { MediaService } from './services/Media/media.service'
import { UrlService } from './services/Url/url.service'
import { BrandService } from './services/Brand/brand.service'
import { CategoryService } from './services/Category/category.service'
import { TagsService } from './services/Tags/tags.service'
import { AttributeSetService } from './services/AttributeSet/attribute-set.service'
import { VariantService } from './services/Variant/variant.service'
import { ProductService } from './services/Product/product.service'
import { AttributeService } from './services/Attribute/attribute.service'
import { ImageUtilsService } from './services/imageUtils/image-utils.service'
import { QuestionService } from './services/Quiz/question.service';
import { QuizService } from './services/Quiz/quiz.service';
import { OrderService } from './services/order/order.service';
import { BannerService } from './services/banner/banner.service';
import { UserService } from './services/User/user.service'
import { AuthloginGuard } from './guards/authlogin.guard'
import { AuthlogoutGuard } from './guards/authlogout.guard'
import { SubscriptionService } from './services/Subscription/subscription.service'
import { CouponService } from './services/coupon/coupon.service'
import { ReviewService } from './services/review/review.service';
import { GlobalService } from './services/global/global.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
    TreeviewModule.forRoot()
  ],
  providers: [
    AuthService,
    AdminGuard,
    AuthloginGuard,
    AuthlogoutGuard,
    SecureInnerPagesGuard,
    CookieService,
    MediaService,
    UrlService,
    GlobalService,
    BrandService,
    CategoryService,
    TagsService,
    AttributeSetService,
    VariantService,
    ProductService,
    AttributeService,
    ImageUtilsService,
    QuestionService,
    QuizService,
    OrderService,
    UserService,
    BannerService,
    SubscriptionService,
    CouponService,
    ReviewService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
